import React, { useState, useContext } from 'react';
import Logo from '../../assets/icon.png';
import routes from '../../constants/routes';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import UserImg from '../../assets/no_user.png';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  PersonOutlined,
} from "@material-ui/icons";
import { FiLogOut } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { LayoutContext } from '../../layouts/Layout';
import { logout } from '../../redux/actions/users';
import {
  Spinner
} from 'react-bootstrap';

const Navbar = props => {
  const { width, activeTheme: { colors } } = useContext(LayoutContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  const [showDropDown, setShowDropDown] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    setTimeout(async () => {
      await dispatch(logout(status => {
        setLoading(false);
        setShowDropDown(false);
      }));
    }, 500);
  };

  return (
    <div>
      {
        width > 768 ?
          <header className="header">
            <nav className="chs-navbar">
              <a className="chs-nav-logo" href={routes["HOME"].path}>
                <img alt="logo" src={Logo} className="chs-nav-logo_img p-2" />
              </a>
              <ul className="chs-nav-menu">
                <li className={`chs-nav-item ${isCurrent(routes["HOME"].path) ? 'active' : ''}`}>
                  <Link to={routes["HOME"].path} className="chs-nav-link">
                    Accueil
                  </Link>
                </li>
                <li className={`chs-nav-item ${isCurrent(routes["NOSBIENS"].path) ? 'active' : ''}`}>
                  <Link to={routes["NOSBIENS"].path} className="chs-nav-link">
                    Nos biens immobiliers
                  </Link>
                </li>
              </ul>
              <div
                className="h-100 d-flex align-items-center justify-content-end pr-4"
              >
                {
                  !!currentUser ?
                    <div
                      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => setShowDropDown(!showDropDown)}
                        src={`${!!currentUser && !!currentUser.profile ? `${process.env.REACT_APP_STORAGE_URL}${currentUser.profile}` : UserImg}`}
                        className="rounded-circle"
                        style={{ height: "50px", width: "50px", margin: "0 10px 0 0", objectFit: "cover" }}
                      />
                      <div
                        onClick={() => setShowDropDown(!showDropDown)}
                        style={{
                          color: "#034D28",
                          fontWeight: "bold",
                          height: "50px",
                        }}
                        className="d-flex align-items-center"
                      >
                        {!!currentUser.name ? currentUser.name : ""}
                      </div>
                      <div
                        className={classnames("chs-dropdown", {
                          "chs-dropdown--active": showDropDown,
                        })}
                      >
                        <ul
                          className="chs-dropdown__list"
                        >
                          <li
                            onClick={() => {
                              setShowDropDown(false);
                              history.push(routes["USERS.PROFILE"].path);
                            }}
                            className="chs-dropdown__list-item"
                          >
                            <span className="chs-dropdown__icon">
                              <FaUserCircle size={20} />
                            </span>
                            <span className="chs-dropdown__title">Mon profil</span>
                          </li>
                          <li
                            style={{ color: colors.primaryLight }}
                            onClick={handleLogout}
                            className="chs-dropdown__list-item border-bottom-0"
                          >
                            <span className="chs-dropdown__icon">
                              <FiLogOut size={20} style={{ transform: "rotate(180deg)" }} />
                            </span>
                            <span className="chs-dropdown__title">
                              Déconnexion
                              {loading && <Spinner animation="grow" size="sm" style={{ marginLeft: "20px" }} />}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    :
                    <>
                      <Button
                        onClick={() => history.push(routes["LOGIN"].path)}
                        color={"#45BE55"}
                        backgroundColor={"white"}
                        className="mr-3 font-weight-bold"
                      >Connexion</Button>
                      <Button
                        onClick={() => history.push(routes["SIGNUP"].path)}
                        backgroundColor={"#45BE55"}
                        className="font-weight-bold"
                      >Inscription</Button>
                    </>
                }
              </div>
            </nav>
          </header>
          :
          <header className="header">
            <nav className="chs-navbar">
              <a className="chs-nav-logo" href={routes["HOME"].path}>
                <img alt="logo" src={Logo} className="chs-nav-logo_img p-2" />
              </a>
              <div
                className="h-100 d-flex align-items-center justify-content-end pr-lg-4"
              >
                <div
                  style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                >
                  <img
                    onClick={() => setShowDropDown(!showDropDown)}
                    src={`${!!currentUser && !!currentUser.profile ? `${process.env.REACT_APP_STORAGE_URL}${currentUser.profile}` : UserImg}`}
                    className="rounded-circle border"
                    style={{ height: "50px", width: "50px", margin: "0 10px 0 0", objectFit: "cover" }}
                  />
                  <div
                    className={classnames("chs-dropdown", {
                      "chs-dropdown--active": showDropDown,
                    })}
                  >
                    <ul
                      className="chs-dropdown__list"
                    >
                      <li
                        onClick={() => {
                          setShowDropDown(false);
                          history.push(routes["HOME"].path);
                        }}
                        className={`chs-dropdown__list-item ${isCurrent(routes["HOME"].path) ? 'active' : ''}`}
                      >
                        <Link className="chs-nav-link">
                          Accueil
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setShowDropDown(false);
                          history.push(routes["NOSBIENS"].path);
                        }}
                        className={`chs-dropdown__list-item ${isCurrent(routes["NOSBIENS"].path) ? 'active' : ''}`}
                      >
                        <Link className="chs-nav-link">
                          Nos biens immobiliers
                        </Link>
                      </li>
                      {
                        !!currentUser?.id ?
                          <>
                            <li
                              onClick={() => {
                                setShowDropDown(false);
                                history.push(routes["USERS.PROFILE"].path);
                              }}
                              className="chs-dropdown__list-item"
                            >
                              <span className="chs-dropdown__icon">
                                <FaUserCircle size={20} />
                              </span>
                              <span className="chs-dropdown__title">Mon profil</span>
                            </li>
                            <li
                              style={{ color: colors.primaryLight }}
                              onClick={handleLogout}
                              className="chs-dropdown__list-item"
                            >
                              <span className="chs-dropdown__icon">
                                <FiLogOut size={20} style={{ transform: "rotate(180deg)" }} />
                              </span>
                              <span className="chs-dropdown__title">
                                Déconnexion
                                {loading && <Spinner animation="grow" size="sm" style={{ marginLeft: "20px" }} />}
                              </span>
                            </li>
                          </>
                          :
                          <>
                            <li
                              style={{ backgroundColor: colors.primaryLight, color: "white" }}
                              onClick={() => history.push(routes["LOGIN"].path)}
                              className="chs-dropdown__list-item border-0 font-weight-bold"
                            >Connexion</li>
                            <li
                              style={{ backgroundColor: colors.primary, color: "white" }}
                              onClick={() => history.push(routes["SIGNUP"].path)}
                              className="chs-dropdown__list-item border-bottom-0 font-weight-bold"
                            >Inscription</li>
                          </>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </header>
      }
      <div className="chs-navbar-fix"></div>
    </div >
  );
};

const Button = styled.div`
  height: 35px;
  min-width: 100px;
  padding: 0 20px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: ${({ color }) => !!color ? color : "white"};
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : "black"};
  &:hover {
    border-radius: 3px;
    box-shadow: ${({ backgroundColor }) => !!backgroundColor && !['#fff', "#FFF", "#FFFFFF", "#ffffff", "white"].includes(backgroundColor) ? `0px 0px 1px 3px ${backgroundColor}40` : "0px 0px 1px 3px #00000005"};
  }
`;

export default Navbar;