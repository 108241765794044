import axios from 'axios';
import { getCookieAsJson } from '../../services/cookie';

// create an axios instance
const service = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	timeout: 60000,
	headers: {
		'Content-Type': 'application/json'
	}
});

// request interceptor
service.interceptors.request.use(async config => {
	const token = getCookieAsJson(process.env.REACT_APP_ACCESS_TOKEN_KEY);
	if (!!token && !!token.access_token) {
		config.headers['Authorization'] = 'Bearer ' + token.access_token;
	}

	return config;
}, error => {
	// gerer les erreurs de la requete
	return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
	response => {
		if (response.status !== 200) {
			return Promise.reject(new Error(res.message || 'Error'));
		} else {
			return response.data;
		}
	},
	error => {
		if (error?.response?.status === 422) {
			error.message = `Les champs de données sont incorrectement remplis`;
		} else if (error?.response?.status === 401) {
			error.message = "Vous n'êtes pas autorisés à continuer. Veuillez vous reconnecter";
		} else {
			error.message = "Une erreur interne est survenue";
		}
		return Promise.reject(error);
	}
);

export default service;