import { TRIGGER_PROFILES } from "../actions/triggers";

const initialState = {
  profile: Date.now()
};

const triggersReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_PROFILES:
      return {
        ...state,
        profile: Date.now()
      };
    default:
      return state;
  }
};

export default triggersReducer;