import React from "react";
import styled from "styled-components";
import { FaSistrix } from 'react-icons/fa';
import PropTypes from 'prop-types';

function SearchBar(props) {
  return (
    <Container className={props.containerClassName} style={{ ...props.containerStyle }}>
      <FaSearchStyled />
      <CustomInput
        className={props.className}
        style={{ ...props.style }}
        placeholder={props.placeholder || ""}
        onChange={props.onChange}
        value={props.value}
      />
    </Container>
  );
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string
};

SearchBar.defaultProps = {
  onChange: () => console.log("Please attach an onHide function to [SearchBar]"),
  containerClassName: "",
  containerStyle: {},
  className: "",
  style: {},
  placeholder: "Rechercher"
};

export default SearchBar;

const Container = styled.div`
  background-color: white;
  border: none;
  border-radius: 100px;
  display: flex;
  align-items:center;
  padding: 5px 20px;
  outline: none;
  position: relative !important;
  width: 65%;
  height: 90%;
`;

const FaSearchStyled = styled(FaSistrix)`
  font-size: 14px;
  opacity: 1;
  color: black;
`;

const CustomInput = styled.input`
  border: none;
  outline: none;
  margin-left: 18px;
  font-size: 14px;
  width: 87%;
`;