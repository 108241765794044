import { AUTHENTICATE, LOGOUT } from "../actions/users";

const initialState = {
	currentUser: null,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return {
				...state,
				currentUser: action.user
			}

		case LOGOUT:
			return {
				...state,
				currentUser: null
			}

		default:
			return state;
	}
};

export default userReducer;