import React, { useState, useEffect, useContext } from "react";
import ProfileBgImg from '../assets/images/profile_bg.png';
import { Row, Card, Col, Spinner } from 'react-bootstrap';
import "../assets/styles.scss";
import { makeStyles, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import UserImg from '../assets/no_user.png';
import { BiBuildingHouse } from 'react-icons/bi';
import { FaCamera, FaHome, FaUserAlt, FaChartPie, FaBookmark, FaTimes } from 'react-icons/fa';
import routes, { GERANT_ROLE } from '../constants/routes';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import extractRoles from '../helpers/extractRoles';
import { LayoutContext } from '../layouts/Layout';

import styled from 'styled-components';
import * as bienService from '../http/biens';
import * as userService from '../http/users';
import { fakeAuth } from '../redux/actions/users';

const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: "white",
		minHeight: "75vh",
		zIndex: 10,
		position: "relative"
	},
	pictureContainer: {
		position: "absolute",
		zIndex: 5,
		left: "50px",
		top: "-75px",
		width: "150px",
		height: "150px",
		display: 'flex',
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
		boxShadow: "0 0 1px 5px #034D2844"
	},
	picture: {
		height: "100%",
		width: "100%",
		borderRadius: "50%",
		objectFit: "cover"
	},
	pictureButton: {
		position: "absolute",
		bottom: 0,
		right: 0,
		cursor: "pointer",
		width: "40px",
		height: "40px",
		backgroundColor: "white",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		boxShadow: "0 0 1px 3px #034D2844",
		"&:hover": {
			boxShadow: "0 0 1px 5px #034D2888",
		}
	},
	leftPanel: {
		backgroundColor: "#F1F7F0",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "50px 10px 10px 10px",
	},
	rightPanel: {
	},
	recents: {
		width: "100%",
		height: "100px",
		objectFit: "cover",
		cursor: "pointer",
		borderRadius: "2px"
	},
	menuContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		height: "70px",
		backgroundColor: "white",
		borderBottom: "0.5px solid #45BE5544"
	},
	menuItem: {
		width: "33.33%",
		height: "100%",
		display: "flex",
		cursor: "pointer",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		color: "black",
		fontWeight: "bold",
		textTransform: "uppercase",
		"&:hover": {
			backgroundColor: "#45BE5544",
			color: "inherit"
		},
		"&.active": {
			backgroundColor: "#45BE55",
			color: "white",
		}
	},
	progressPrimary: {
		color: "#034D28"
	}
}));

const Profile = ({ children, ...rest }) => {
	const { width, activeTheme: { colors } } = useContext(LayoutContext);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const currentUser = useSelector(state => state.user.currentUser);

	const profileListener = useSelector(state => state.triggers.profile);

	const isCurrent = url => {
		return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
	};

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [annonces, setAnnonces] = useState([]);

	const fetchAnnonces = async (verbose = true) => {
		if (
			extractRoles(
				!!currentUser &&
					!!currentUser.roles ?
					currentUser.roles :
					[]
			).includes(GERANT_ROLE)
		) {
			verbose && setLoading(true);
			await bienService.getAllAnnonces().then(response => {
				if (response.success) {
					setAnnonces(response.data.biens);
				} else {
					setErrorMessage(response.message);
				}
				setLoading(false);
			}).catch(error => {
				setErrorMessage(error.message);
				setLoading(false);
			});
		}
	};

	let mounted = true;
	useEffect(() => {
		fetchAnnonces();
		return () => {
			mounted = false;
		}
	}, [profileListener]);

	const profileInput = React.useRef(null);

	const [progress, setProgress] = useState(0);

	const handleChangePicture = async event => {
		const data = new FormData();
		if (event?.target?.files[0]?.size > 2097152) {
			setErrorMessage("Taille de fichier trop élevée (2 Mo maximum) !");
			return;
		}
		data.append("image", event.target.files[0]);
		await userService.uploadProfilePicture(
			data,
			status => setProgress(status.loaded / status.total)
		).then(response => {
			if (response.success) {
				dispatch(fakeAuth(response.data.user));
			} else {
				setErrorMessage(response.message);
			}
			setProgress(0);
		}).catch(err => {
			setErrorMessage(err.message);
		});
	};

	return (
		<div
			style={{
				height: "100vh",
				background: `url(${ProfileBgImg}) no-repeat center bottom fixed`,
				overflowY: "auto",
				padding: "150px 0 0 0",
				position: "relative"
			}}
		>
			<input
				type="file"
				accept=".jpg,.jpeg,.png"
				ref={profileInput}
				onChange={handleChangePicture}
				style={{ display: "none" }}
			/>
			{!!errorMessage &&
				<Alert
					style={{ position: "fixed", top: "-30px", right: "10px", width: "300px", zIndex: 20 }}
					className="px-3 py-2 rounded-sm mt-5"
					color={`white`}
					backgroundColor={`${colors.primary}`}
				>
					<span className="w-95 line-height-2">
						{errorMessage}
					</span>
					<FaTimes
						size={20}
						color={`white`}
						style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
						onClick={() => setErrorMessage("")}
					/>
				</Alert>
			}
			<Row className={classnames(classes.container, "mx-auto p-0")}>
				<div className={classnames(classes.pictureContainer)}>
					<img src={`${!!currentUser && !!currentUser.profile ? `${process.env.REACT_APP_STORAGE_URL}${currentUser.profile}` : UserImg}`} className={classes.picture} />
					<div onClick={progress > 0 ? () => { } : () => profileInput.current.click()} className={classes.pictureButton}>
						{progress > 0 ?
							<CircularProgress variant="determinate" color="primary" classes={{ colorPrimary: classes.progressPrimary }} value={parseInt(progress * 100)} />
							:
							<FaCamera size={20} />
						}
					</div>
				</div>
				<Col md={4} lg={4} xs={12} className={"p-3 m-0"}>
					<div
						className={classnames(classes.leftPanel, "h-100 pb-5")}
					>
						<div className="w-100 d-flex align-items-center justify-content-end mb-3">
							<span onClick={() => history.push(routes["HOME"].path)} style={{ cursor: "pointer" }}>Retour à l'accueil</span>
							<div onClick={() => history.push(routes["HOME"].path)} style={{ cursor: "pointer", marginLeft: "10px", backgroundColor: "#45BE5588", height: "50px", borderRadius: "50%", width: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<FaHome size={20} color={"black"} />
							</div>
						</div>
						<h2 className="w-100 px-2">{!!currentUser && !!currentUser.name ? currentUser.name : ""}</h2>
						<span className="w-100 px-2">{!!currentUser && !!currentUser.email ? currentUser.email : ""}</span>
						<span className="w-100 px-2 font-weight-bold">
							{!!currentUser &&
								!!currentUser.roles &&
								extractRoles(currentUser.roles).includes(GERANT_ROLE) ?
								`Annonceur` :
								`Visiteur`}
						</span>
						<span className="mt-5 w-100 text-right px-2 font-80">
							{loading && <Spinner animation="grow" size="sm" color="white" style={{ marginRight: "20px" }} />}
							Derniers biens postés
						</span>
						{
							annonces.length === 0 ?
								!loading &&
								<div className="w-100 text-center my-5">
									{
										extractRoles(
											!!currentUser &&
												!!currentUser.roles ?
												currentUser.roles :
												[]
										).includes(GERANT_ROLE) ?
											"Aucune annonce trouvée"
											:
											"Vous n'êtes pas encore un annonceur"
									}
								</div>
								:
								<Row className="w-100 mx-auto p-0 mt-4">
									{annonces?.slice(0, width > 768 ? 6 : 4)?.map((annonce, index) => (
										<Col key={index.toString()} md={4} lg={4} xs={6} className="mt-3 p-0 px-2">
											<img src={`${process.env.REACT_APP_STORAGE_URL}${annonce.images[0].uri}`} className={classnames(classes.recents, "shadow-sm border-0")} />
										</Col>
									))}
								</Row>
						}
					</div>
				</Col>
				<Col md={8} lg={8} xs={12} className={"pl-3 pl-lg-0 pr-lg-3 py-3 m-0"}>
					<div className={classnames(classes.menuContainer)}>
						<Link to={routes["USERS.PROFILE"].path} className={classnames(classes.menuItem, `${isCurrent(routes["USERS.PROFILE"].path) ? 'active' : ''}`)}>
							{
								width > 768 ?
									<span>
										Informations
									</span>
									:
									<FaUserAlt size={20} />
							}
						</Link>
						<Link to={routes["USERS.FAVORIS"].path} className={classnames(classes.menuItem, `${isCurrent(routes["USERS.FAVORIS"].path) ? 'active' : ''}`)}>
							{
								width > 768 ?
									<span>Favoris</span>
									:
									<FaBookmark size={20} />
							}
						</Link>
						{!!currentUser &&
							!!currentUser.roles &&
							extractRoles(currentUser.roles).includes(GERANT_ROLE) ?
							<Link
								to={routes["USERS.GERANT.INDEX"].path}
								className={classnames(classes.menuItem, `${isCurrent("annonceur") ? 'active' : ''}`)}
							>
								{
									width > 768 ?
										<span>Espace annonceur</span>
										:
										<BiBuildingHouse size={20} />
								}
							</Link>
							:
							<Link to={routes["USERS.BECOME"].path} className={classnames(classes.menuItem, `${isCurrent(routes["USERS.BECOME"].path) ? 'active' : ''}`)}>
								{
									width > 768 ?
										<span>Devenir annonceur</span>
										:
										<BiBuildingHouse size={20} />
								}
							</Link>
						}
					</div>
					<div
						className={classnames(classes.rightPanel, "mt-3 mx-auto")}
					>
						{children}
					</div>
				</Col>
			</Row>
		</div>
	);
};


const Alert = styled.div`
  min-width: 200px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  color: ${({ color }) => !!color ? color : "black"};
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : "#34CE80"};
`;

export default Profile;