import { CLEAR_CONSTANTS, SET_CONSTANTS } from "../actions/constants";

const initialState = {};

const constantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSTANTS:
      return action.constants;

    case CLEAR_CONSTANTS:
      return state;

    default:
      return state;
  }
};

export default constantsReducer;