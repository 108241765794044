import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from '../../layouts/Layout';

const usetyles = makeStyles(() => ({
  h1: {
    color: "#334D6E",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
  },
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    width: "100%",
  },
}));

const ConfirmModal = props => {
  const classes = usetyles();
  const { width, activeTheme: { colors } } = useContext(LayoutContext);

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <Close size={20} style={{ position: "absolute", right: "20px", top: "20px", cursor: "pointer" }} onClick={props.onHide.bind(this, false)} />
      <div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column p-4 mt-5">
        <span
          className={props.messageClassName}
          style={{
            ...props.messageStyle
          }}
        >{props.message}</span>
        <div className="d-flex align-items-center my-4 w-100" style={{ justifyContent: "space-evenly" }}>
          <Button
            className={props.cancelClassName}
            backgroundColor={`${colors.danger}`}
            color={"white"}
            style={{
              textTransform: "uppercase",
              ...props.successStyle
            }}
            onClick={props.onHide.bind(this, false)}
          >
            {props.cancelLabel}
          </Button>
          <Button
            className={props.successClassName}
            backgroundColor={colors.primary}
            color={"white"}
            style={{
              textTransform: "uppercase",
              ...props.successStyle
            }}
            onClick={props.onHide.bind(this, true)}
          >
            {props.successLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  successLabel: PropTypes.string,
  successStyle: PropTypes.object,
  successClassName: PropTypes.string,
  cancelStyle: PropTypes.object,
  cancelLabel: PropTypes.string,
  cancelClassName: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModal.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  successLabel: "Oui",
  cancelLabel: "Non",
  cancelStyle: {},
  successStyle: {},
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [ConfirmModal]"),
};

const Button = styled.div`
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: ${({ color }) => !!color ? color : "white"};
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : "#34CE80"};
  &:hover {
    border-radius: 3px;
    box-shadow: ${({ backgroundColor }) => !!backgroundColor && !['#fff', "#FFF", "#FFFFFF", "#ffffff", "white"].includes(backgroundColor) ? `0px 0px 1px 3px ${backgroundColor}40` : "0px 0px 1px 3px #34CE8005"};
  }
`;

export default ConfirmModal;