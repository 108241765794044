import React, { useState } from "react";
import classnames from "classnames";
import { GoMail } from 'react-icons/go';
import { FaTimes, FaRegChartBar, FaUser, FaBuilding, FaRegCommentAlt } from "react-icons/fa";
import { FiLogOut, FiRadio } from "react-icons/fi";
import styled from "styled-components";
import routes from "../../constants/routes";
import {
  makeStyles,
} from "@material-ui/core";
import { Spinner } from 'react-bootstrap';
import { useLocation, Link, useHistory } from "react-router-dom";
import Logo from "../../assets/icon_white.png";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/users';

import ConfirmModal from '../../components/confirm-modal';

const useStyles = makeStyles(() => ({
  //
}));

const AdminSideBar = ({
  visible,
  handleToggle,
  blurContent,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirm = () => setShowConfirm(true);

  const [loading, setLoading] = useState(false);

  const handleLogout = async status => {
    setShowConfirm(false);
    if (status === true) {
      setLoading(true);
      setTimeout(async () => {
        await dispatch(logout(status => {
          setLoading(false);
          history.replace(routes["ADMIN.LOGIN"].path);
        }));
      }, 500);
    }
  };

  return (
    <aside
      style={blurContent ? { filter: "blur(0.5rem)" } : {}}
      className={classnames("chs-admin-sidebar ", visible ? "active" : "")}
    >
      <ConfirmModal
        message="Voulez-vous vraiment vous déconnecter ?"
        visible={showConfirm}
        onHide={handleLogout}
      />
      <div className="chs-admin-sidebar__brand">
        <a className="chs-admin-sidebar__brand-link" href={routes["ADMIN.DASHBOARD"].path}>
          <img alt="" src={Logo} className="chs-admin-sidebar__brand-img" />
        </a>
        <FaTimes size={20} className="chs-admin-sidebar__brand-close" onClick={handleToggle} />
      </div>

      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.DASHBOARD"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.DASHBOARD"].path} className={`chs-admin-sidebar-item-link`}>
          <FaRegChartBar size={20} className="mr-2" />
          <span>Tableau de bord</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>
      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.USERS"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.USERS"].path} className={`chs-admin-sidebar-item-link`}>
          <FaUser size={20} className="mr-2" />
          <span>Gérer les utilisateurs</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>
      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.GERANTS"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.GERANTS"].path} className={`chs-admin-sidebar-item-link`}>
          <FaBuilding size={20} className="mr-2" />
          <span>Gérer les annonceurs</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>
      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.MAILS"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.MAILS"].path} className={`chs-admin-sidebar-item-link`}>
          <GoMail size={20} className="mr-2" />
          <span>Mails</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>
      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.COMMENTS"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.COMMENTS"].path} className={`chs-admin-sidebar-item-link`}>
          <FaRegCommentAlt size={18} className="mr-2" />
          <span>Commentaires</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>
      <div className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.DIFFUSIONS"].path) ? 'active' : ''}`}>
        <div className="chs-admin-sidebar-item-top"></div>
        <div className="chs-admin-sidebar-item-left"></div>
        <Link to={routes["ADMIN.DIFFUSIONS"].path} className={`chs-admin-sidebar-item-link`}>
          <FiRadio size={20} className="mr-2" />
          <span>Diffusions</span>
        </Link>
        <div className="chs-admin-sidebar-item-bottom"></div>
      </div>

      <div className="d-flex w-100 align-items-center justify-content-center mt-2">
        {
          loading ?
            <Spinner size={"lg"} animation="grow" style={{ color: "white" }} />
            :
            <FiLogOut onClick={handleShowConfirm} style={{ cursor: "pointer", transform: "rotate(180deg)" }} color="white" size={30} />
        }
      </div>
    </aside>
  );
};

export default AdminSideBar;