import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LayoutContext } from '../Layout';
import styled from 'styled-components';
import { FaFacebookF, FaWhatsapp, FaInstagram, FaAngleUp, FaFacebookMessenger } from 'react-icons/fa';
import { IoLogoTiktok } from 'react-icons/io5';
import LogoImg from '../../assets/icon.png';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../constants/routes';

const Footer = props => {
  const history = useHistory();
  const { width, activeTheme: { colors } } = useContext(LayoutContext);

  return (
    <>
      <footer className="footer w-100 pb-5">
        <Row className="w-100 px-3 pt-5 mx-auto d-flex align-items-start">
          <h5 className="text-uppercase text-black font-weight-bold w-100 px-3">à propos de nous</h5>
          <Col md={4} xs={12} lg={4}>
            <p>
              Chezsoi représente une plateforme immobilière intervenant dans l’acquisition ou la location de biens immobiliers. Elle met en relation les locataires et les propriétaires des biens afin de garantir une véritable transparence dans les transactions. Elle intervient à Cotonou, Calavi et Seme.
            </p>
          </Col>
          <Col md={4} xs={12} lg={4}>
            <p>
              Chezsoi constitue une branche de GMA style laquelle intervient dans l’univers global de l’immobilier. Les activités de GMA Style s’étendent également dans les secteurs de travaux d’imprimerie et de commerce général et aussi en ligne.
            </p>
          </Col>
          <Col md={4} xs={12} lg={4} className="d-flex justify-content-end">
            <div onClick={() => window.scrollTo(0, 0)} className="bg-principal rounded-2 btn d-flex align-items-center justify-content-center" style={{ width: "35px", height: "35px" }}>
              <FaAngleUp color="white" size={20} />
            </div>
          </Col>
        </Row>
        <Row className="w-100 px-3 mx-auto d-flex align-items-start">
          <Col md={4} xs={12} lg={4} className="mt-3">
            <h5 className="text-uppercase text-black font-weight-bold">Contactez-nous</h5>
            <span>
              support@chezsoi.bj
            </span><br />
            <span>
              +229 97 42 59 40
            </span><br />
            <div className="d-flex align-items-center mt-2">
              <a href={"https://fb.me/chezsoibj"} target="_blank">
                <FaFacebookF size={16} className="text-principal" style={{ marginRight: "8px", cursor: "pointer" }} />
              </a>
              <a href={"https://me.me/chezsoibj"} target="_blank">
                <FaFacebookMessenger size={20} className="text-principal" style={{ marginRight: "8px", cursor: "pointer" }} />
              </a>
              <a href={"https://www.instagram.com/chezsoi.bj"} target="_blank">
                <FaInstagram size={20} className="text-principal" style={{ marginRight: "8px", cursor: "pointer" }} />
              </a>
              <a href={"https://vm.tiktok.com/ZMRFjkopx"} target="_blank">
                <IoLogoTiktok size={20} className="text-principal" style={{ marginRight: "8px", cursor: "pointer" }} />
              </a>
            </div>
          </Col>
          <Col md={4} xs={12} lg={4} className="mt-3">
            <h5 className="text-uppercase text-black font-weight-bold">accès rapides</h5>
            <p>
              <Link style={{ color: "#00000087" }} to={routes["NOSBIENS"].path}>
                Nos biens immobiliers
              </Link>
            </p>
          </Col>
          <Col md={4} xs={12} lg={4} className="d-flex flex-column align-items-end justify-content-end">
            <img alt="logo" src={LogoImg} style={{ height: "40px", objectFit: "contain" }} />
            <p className="my-2">
              Copyright &copy; 2021
            </p>
            <p style={{ cursor: "pointer" }} onClick={() => history.push(routes["TERMS.CONDITIONS"].path)}>
              Politique de confidentialité
            </p>
          </Col>
        </Row>
      </footer>
    </>
  );
};

const SubscribeInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-size: 16px;
`;

export default Footer;