import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import usersReducer from './reducers/users';
import constantsReducer from './reducers/constants';
import triggersReducer from './reducers/triggers';

const rootReducer = combineReducers({
  user: usersReducer,
  constants: constantsReducer,
  triggers: triggersReducer
});

export default createStore(rootReducer, applyMiddleware(ReduxThunk));