import React from "react";
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import "../assets/styles.scss";
import ImageBg from '../assets/images/main1.png';

const Default = ({ children, ...rest }) => {
  return (
    <div>
      <NavBar />
      <div
        style={{
          minHeight: "100vh",
          background: `url(${ImageBg}) no-repeat center fixed`,
          backgroundSize: "cover"
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Default;