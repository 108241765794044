export const ADMIN_ROLE = 'ADMIN';
export const GUEST_ROLE = 'GUEST';
export const GERANT_ROLE = 'GERANT';

const routes = {
	'HOME': { path: '/', roles: [] },
	'NOSBIENS': { path: '/nos-biens', roles: [] },
	'CARTE': { path: '/vue-carte', roles: [] },
	'RESULTS': { path: '/resultats', roles: [] },
	'DETAILS': { path: '/details/:uuid', roles: [] },
	'TERMS.CONDITIONS': { path: '/terms-conditions', roles: [] },
	'LOGIN': { path: '/login', roles: [] },
	'SIGNUP': { path: '/register', roles: [] },
	'VERIFY.EMAIL.INDEX': { path: '/verify-email', roles: [] },
	'VERIFY.EMAIL.REDIRECT': { path: '/verify-redirect', roles: [] },
	'ERROR': { path: '/error', roles: [] },
	'ADMIN.LOGIN': { path: '/dashboard/login', roles: [] },
	'ADMIN.DASHBOARD': { path: '/dashboard', roles: [ADMIN_ROLE] },
	'ADMIN.COMMENTS': { path: '/dash/comments', roles: [ADMIN_ROLE] },
	'ADMIN.COMMENTS.DETAILS': { path: '/dash/comments-details', roles: [ADMIN_ROLE] },
	'ADMIN.GERANTS': { path: '/dash/annonceurs', roles: [ADMIN_ROLE] },
	'ADMIN.GERANTS.DETAILS': { path: '/dash/annonceurs-details', roles: [ADMIN_ROLE] },
	'ADMIN.USERS': { path: '/dash/utilisateurs', roles: [ADMIN_ROLE] },
	'ADMIN.MAILS': { path: '/dash/mails', roles: [ADMIN_ROLE] },
	'ADMIN.DIFFUSIONS': { path: '/dash/diffusions', roles: [ADMIN_ROLE] },
	'ADMIN.DIFFUSIONS.PUBLISH': { path: '/dash/diffusions-publish', roles: [ADMIN_ROLE] },
	'USERS.ADD': { path: '/annonceurs/add', roles: [GERANT_ROLE] },
	'USERS.BECOME': { path: '/annonceurs/registration', roles: [GUEST_ROLE] },
	'USERS.COMMENTS': { path: '/annonceurs/commentaires', roles: [GERANT_ROLE] },
	'USERS.FAVORIS': { path: '/favoris', roles: [GUEST_ROLE] },
	'USERS.PROFILE': { path: '/profil', roles: [GUEST_ROLE] },
	'USERS.GERANT.INDEX': { path: "/espace-annonceur", roles: [GERANT_ROLE] },
	'USERS.GERANT.TRASH': { path: "/annonceurs-trash", roles: [GERANT_ROLE] },
	'USERS.GERANT.EDIT': { path: "/annonceurs/edit/:uuid", roles: [GERANT_ROLE] }
};

export default routes;