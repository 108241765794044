export const themeConfigs = {
	light: {
		colors: {
			// primary: "#034D28",
			primary: "#032E26",
			primaryOne: "#DECCE9A3",
			primaryDark: "#00261B",
			primaryLight: "#168038",
			secondary: "#DECCE9A3",
			danger: "#F90000",
			warning: "#ffb42a",
			transparent: "rgba(0, 0, 0, 0.07)",
			gray: "#707070",
			background: "#FFFFFF",
			text: "#555",
			border: "#ddd"
		},
	},
	dark: {
		colors: {
			primary: "#034D28",
			primaryOne: "#DECCE9A3",
			primaryDark: "#00261B",
			primaryLight: "#168038",
			secondary: "#DECCE9A3",
			danger: "#F90000",
			warning: "#ffb42a",
			transparent: "rgba(0, 0, 0, 0.07)",
			gray: "#707070",
			background: "#FFFFFF",
			text: "#555",
			border: "#ddd"
		}
	}
};

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';

export const FULL_LAYOUT = 'full';
export const DEFAULT_LAYOUT = 'default';
export const ADMIN_LAYOUT = "admin";
export const PROFILE_LAYOUT = "profile";

export const layoutConfigs = {
	full: FULL_LAYOUT,
	default: DEFAULT_LAYOUT,
	admin: ADMIN_LAYOUT,
	profile: PROFILE_LAYOUT
};