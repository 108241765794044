import request from '../request';

export const getStartupData = () => {
	return request({
		url: `startup`,
		method: 'GET'
	});
};

export const getAllCategories = () => {
	return request({
		url: `categories`,
		method: 'GET'
	});
};