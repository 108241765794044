import request from '../request';

export const getAllBiens = (filter = "", offset = 0, limit = 10) => {
  return request({
    url: `biens/filter/${filter}/offset/${offset}/limit/${limit}`,
    method: 'GET'
  });
};

export const getAllBiensFavoris = () => {
  return request({
    url: `biens-favoris`,
    method: 'GET'
  });
};

export const getAllBiensNearby = data => {
  return request({
    url: `biens-nearby`,
    method: 'POST',
    data
  });
};

export const searchBiens = (data, offset = 0, limit = 10) => {
  return request({
    url: `biens/search/offset/${offset}/limit/${limit}`,
    method: 'POST',
    data
  });
};

export const getBienByID = (bien_id) => {
  return request({
    url: `biens/${bien_id}`,
    method: 'GET'
  });
};

export const getBienByUUID = (uuid, user_id = null) => {
  return request({
    url: `biens-by-uuid/${uuid}`,
    method: 'POST',
    data: { user_id }
  });
};

export const addBienToFavorites = (bien_id) => {
  return request({
    url: `biens/${bien_id}/favorite`,
    method: 'POST'
  });
};

export const removeBienFromFavorites = (bien_id) => {
  return request({
    url: `biens/${bien_id}/unfavorite`,
    method: 'POST'
  });
};

export const rateBien = (data) => {
  return request({
    url: `ratings`,
    method: 'POST',
    data
  });
};

export const getMyRate = (bien_id) => {
  return request({
    url: `ratings/${bien_id}`,
    method: 'GET'
  });
};

///////////////////////////////////////////////////////////////////////////////////////////////////
export const getAllAnnonces = () => {
  return request({
    url: `annonces`,
    method: 'GET'
  });
};

export const getAllAnnoncesInTrash = () => {
  return request({
    url: `annonces-trash`,
    method: 'GET'
  });
};

export const getAnnonceByID = (annonce_id) => {
  return request({
    url: `annonces/${annonce_id}`,
    method: 'GET'
  });
};

export const getAnnonceByUUID = (uuid) => {
  return request({
    url: `annonces/${uuid}`,
    method: 'GET'
  });
};

export const storeAnnonce = (data) => {
  return request({
    url: `annonces`,
    method: 'POST',
    data
  });
};

export const updateAnnonce = (annonce_id, data) => {
  return request({
    url: `annonces/${annonce_id}`,
    method: 'PUT',
    data
  });
};

export const setFeaturesOnAnnonce = (annonce_id, data) => {
  return request({
    url: `annonces/${annonce_id}/features`,
    method: 'PUT',
    data
  });
};

export const removeFeaturesFromAnnonce = (annonce_id, feature_id) => {
  return request({
    url: `annonces/${annonce_id}/features/${feature_id}`,
    method: 'DELETE'
  });
};

export const deleteAnnonce = (annonce_id, password) => {
  return request({
    url: `annonces/${annonce_id}`,
    method: 'DELETE',
    data: { password }
  });
};