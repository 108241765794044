import request from '../request';

///////////////////////////////////////////////////////////////////////////////////////////////////
export const authMe = () => {
	return request({
		url: 'auth/me',
		method: 'GET'
	});
};

export const login = (data) => {
	return request({
		url: 'login',
		method: 'POST',
		data
	});
};

export const signup = (data) => {
	return request({
		url: 'signup',
		method: 'POST',
		data
	});
};

export const logout = () => {
	return request({
		url: 'logout',
		method: 'POST'
	});
};

export const googleAuthVerify = (id_token) => {
	return request({
		url: 'googleauth',
		method: "POST",
		data: { id_token }
	});
};

///////////////////////////////////////////////////////////////////////////////////////////////////
export const resendEmailVerificationCode = data => {
	return request({
		url: "signup/verify/resend",
		method: "POST",
		data
	});
};

///////////////////////////////////////////////////////////////////////////////////////////////////
export const subscribeResetPassword = data => {
	return request({
		url: 'reset/subscribe',
		method: 'POST',
		data
	});
};

export const resetPassword = data => {
	return request({
		url: `reset/update`,
		method: 'POST',
		data
	});
};

///////////////////////////////////////////////////////////////////////////////////////////////////
export const getUser = (user_id) => {
	return request({
		url: `users/${user_id}`,
		method: 'GET'
	});
};

export const update = (user_id, data) => {
	return request({
		url: `users/${user_id}`,
		method: 'PUT',
		data
	});
};

export const updatePassword = (user_id, data) => {
	return request({
		url: `users/${user_id}/password`,
		method: 'PUT',
		data
	});
};

export const uploadProfilePicture = (data, handleProgress = () => { }) => {
	return request({
		url: `users/picture/upload`,
		method: 'POST',
		timeout: null,
		data,
		onUploadProgress: handleProgress
	});
};

export const getAllUsers = data => {
	return request({
		url: `users`,
		method: 'POST',
		data
	});
};

export const getAllGerants = data => {
	return request({
		url: `gerants-all`,
		method: 'POST',
		data
	});
};

export const getAllBiensOfGerants = (gerant_id) => {
	return request({
		url: `gerants-biens/${gerant_id}`,
		method: 'GET',
	});
};

export const blockUser = (user_id, password) => {
	return request({
		url: `users/${user_id}`,
		method: 'DELETE',
		data: { password }
	});
};

export const restoreUser = (user_id, password) => {
	return request({
		url: `users-restore/${user_id}`,
		method: 'PUT',
		data: { password }
	});
};

export const becomeGerant = data => {
	return request({
		url: "users/become/gerant",
		method: "POST",
		data
	});
};