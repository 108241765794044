import React, { useContext } from 'react';
import Logo from '../../assets/icon.png';
import routes from '../../constants/routes';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { LayoutContext } from '../../layouts/Layout';
import UserImg from '../../assets/no_user.png';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { FaRegCommentAlt, FaBell, FaHome } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import SearchBar from '../../components/search-bar';
import { useSelector } from 'react-redux';

const AdminNavbar = ({
  toggleSidebar,
  blurContent,
  ...props
}) => {
  const history = useHistory();
  const { width, AdminLayout, activeTheme: { colors } } = useContext(LayoutContext);
  const currentUser = useSelector(state => state.user.currentUser);

  return (
    <header style={blurContent ? { filter: "blur(0.5rem)" } : {}} className="chs-admin-navbar">
      <AiOutlineMenuUnfold size={30} className="chs-admin-navbar__brand" onClick={toggleSidebar} />

      <SubmitButton
        onClick={() => history.push(routes["HOME"].path)}
        backgroundColor={colors.primaryDark}
        className="font-weight-bold"
      >
        {width > 768 ?
          "Accès au site"
          :
          <FaHome size={20} color="white" />
        }
      </SubmitButton>

      {width > 768 &&
        <SearchBar
          placeholder="Recherche"
          containerStyle={{ height: "40px", width: "30%", backgroundColor: "#ddd" }}
          style={{ fontSize: "13px", height: "100%", backgroundColor: "inherit", color: "#555" }}
        />
      }

      <div className="d-flex align-items-center h-100">
        <div onClick={() => history.push(routes["ADMIN.MAILS"].path)} style={{ position: "relative", cursor: "pointer", width: "50px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <span style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: colors.danger, position: "relative", top: "-10px", right: "-25px" }}></span>
          <GoMail size={25} color={"#888"} />
        </div>
        <div onClick={() => history.push(routes["ADMIN.COMMENTS"].path)} style={{ position: "relative", cursor: "pointer", width: "50px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          {/* <span style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: colors.danger, position: "relative", top: "-10px", right: "-25px" }}></span> */}
          <FaRegCommentAlt size={20} color={"#888"} />
        </div>
        {/* <div onClick={() => history.push(routes["ADMIN.NOTIFICATIONS"].path)} style={{ position: "relative", cursor: "pointer", width: "50px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <span style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: colors.danger, position: "relative", top: "-9px", right: "-24px" }}></span>
          <FaBell size={20} color={"#888"} />
        </div> */}
        <div
          className="h-100 d-flex align-items-center justify-content-end pr-lg-4 pl-3"
        >
          <div>
            <img
              src={`${!!currentUser && !!currentUser.profile ? `${process.env.REACT_APP_STORAGE_URL}${currentUser.profile}` : UserImg}`}
              className="rounded-circle" style={{ height: "50px", width: "50px", margin: "0 10px 0 0", objectFit: "cover" }}
            />
            {width > 768 &&
              <span style={{ color: "#034D28", fontWeight: "bold" }}>
                {currentUser?.name}
              </span>
            }
          </div>
        </div>
      </div>
    </header>
  );
};

const SubmitButton = styled.div`
  min-width: 40px;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 15px;
  color: ${({ color }) => !!color ? color : "white"};
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : "#34CE80"};
  &:hover {
    border-radius: 15px;
    box-shadow: ${({ backgroundColor }) => !!backgroundColor && !['#fff', "#FFF", "#FFFFFF", "#ffffff", "white"].includes(backgroundColor) ? `0px 0px 1px 3px ${backgroundColor}40` : "0px 0px 1px 3px #34CE8005"};
  }
`;

export default AdminNavbar;