import * as startupService from '../../http/startup';

export const SET_CONSTANTS = 'SET_CONSTANTS';
export const setConstants = (callback = () => { }) => {
  return async (dispatch, getState) => {
    await startupService.getStartupData().then(response => {
      if (response.success) {
        dispatch({
          type: SET_CONSTANTS,
          constants: response.data
        });
        callback({
          success: true,
          message: "DONE"
        });
      } else {
        callback({
          success: false,
          message: response.message
        });
      }
    }).catch(error => {
      callback({
        success: false,
        message: "SERVICE_FAILED[error] : " + error.message
      });
    });
  }
};

export const CLEAR_CONSTANTS = 'CLEAR_CONSTANTS';
export const clearConstants = () => {
  return { type: CLEAR_CONSTANTS };
};