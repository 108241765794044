import React from "react";
import FullLayout from "./Full";
import DefaultLayout from "./Default";
import AdminLayout from './Admin';
import ProfileLayout from './Profile';

import {
	themeConfigs,
	layoutConfigs,
	LIGHT_THEME,
	DARK_THEME,
	FULL_LAYOUT,
	DEFAULT_LAYOUT,
	ADMIN_LAYOUT,
	PROFILE_LAYOUT
} from '../constants/theme';

const LayoutContext = React.createContext();
class LayoutProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sizes: themeConfigs.sizes,
			activeLayout: layoutConfigs.default,
			activeTheme: themeConfigs.light,
			width: window.innerWidth,
		};
	}

	updateWidth = () => {
		this.setState({
			width: window.innerWidth,
		});
	};

	componentDidMount = () => {
		if (window !== "undefined") {
			window.addEventListener("resize", this.updateWidth());
		}
	};

	render() {
		const { children } = this.props;
		return (
			<LayoutContext.Provider
				value={{
					...this.state,
					FullLayout,
					DefaultLayout,
					ProfileLayout,
					AdminLayout,
					switchLayout: (layout) => {
						!!layout &&
							(
								String(layout) === DEFAULT_LAYOUT ||
								String(layout) === PROFILE_LAYOUT ||
								String(layout) === ADMIN_LAYOUT ||
								String(layout) === FULL_LAYOUT
							) &&
							this.setState({ activeLayout: layout });
					},
					switchTheme: (theme) => {
						!!theme &&
							(
								String(theme) === LIGHT_THEME ||
								String(theme) === DARK_THEME
							) &&
							this.setState({ activeTheme: themeConfigs[theme] });
					},
				}}
			>
				{children}
			</LayoutContext.Provider>
		);
	}
}

const LayoutConsumer = LayoutContext.Consumer;
export { LayoutProvider, LayoutContext, LayoutConsumer };