import Cookies from 'js-cookie';

export const setCookie = (key, value) => {
	Cookies.set(key, value);
};

export const getCookie = key => {
	return Cookies.get(key);
};

export const getCookieAsJson = key => {
	try {
		return Cookies.getJSON(key);
	} catch (error) {
		console.log("GET_COOKIE_AS_JSON[error]");
		return null;
	}
};

export const removeCookie = key => {
	try {
		Cookies.remove(key);
		return { success: true };
	} catch (error) {
		console.log("REMOVE_COOKIE[error]");
		return { success: false, message: error.message };
	}
};