import React from "react";
import AdminNavbar from './components/AdminNavbar';
import AdminSidebar from './components/AdminSidebar';
import classnames from 'classnames';
import "../assets/styles.scss";

const Admin = ({ children, blurContent, ...rest }) => {
	const [showSidebar, setShowSidebar] = React.useState(false);

	React.useEffect(() => {
		if (window !== "undefined") {
			window.addEventListener("resize", addResizeListeners());
		}
	}, []);

	const addResizeListeners = () => {
		const width = window.innerWidth;
		if (width > 750) {
			setShowSidebar(true);
		}
	}

	const toggleShowSidebar = () => {
		setShowSidebar(!showSidebar);
	};

	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				overflow: "hidden",
			}}
		>
			<AdminSidebar
				visible={showSidebar}
				handleToggle={toggleShowSidebar}
				blurContent={blurContent}
			/>
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<AdminNavbar
					toggleSidebar={toggleShowSidebar}
					blurContent={blurContent}
				/>
				<main className={classnames("chs-admin-main")}>
					{children}
				</main>
			</div>
		</div>
	);
};

export default Admin;