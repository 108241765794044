import * as usersService from '../../http/users';
import { removeCookie, setCookie } from '../../services/cookie';

export const fakeAuth = currentUser => {
	return {
		type: AUTHENTICATE,
		user: currentUser
	};
};

export const AUTHENTICATE = 'AUTHENTICATE';
export const authenticate = (callback = () => { }) => {
	return async (dispatch, getState) => {
		await usersService.authMe().then(auth => {
			if (auth.success) {
				dispatch({
					type: AUTHENTICATE,
					user: auth.data.user
				});
				callback({
					success: true,
					message: "DONE"
				});
			} else {
				callback({
					success: false,
					message: auth.message
				});
			}
		}).catch(error => {
			callback({
				success: false,
				message: "SERVICE_FAILED[error] : " + error.message
			});
		});
	}
}

export const login = (loginObj, callback = () => { }) => {
	return async (dispatch, getState) => {
		await setCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY, JSON.stringify(loginObj));

		await usersService.authMe().then(auth => {
			if (auth.success) {
				dispatch({
					type: AUTHENTICATE,
					user: auth.data.user
				});
				callback({
					success: true,
					message: "DONE"
				});
			} else {
				callback({
					success: false,
					message: auth.message
				});
			}
		}).catch(error => {
			callback({
				success: false,
				message: "SERVICE_FAILED[error] : " + error.message
			});
		});
	}
}

export const LOGOUT = 'LOGOUT';
export const logout = (callback = () => { }) => {
	return async (dispatch, getState) => {
		await usersService.logout().then(async status => {
			const remove = removeCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY);
			if (remove.success) {
				dispatch({
					type: LOGOUT
				});
				callback({
					success: true,
					message: "DONE"
				});
			} else {
				callback({
					success: false,
					message: remove.message
				});
			}
		}).catch(error => {
			callback({
				success: false,
				message: error.message
			});
		});
	}
}