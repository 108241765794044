import React from "react";
import styled from "styled-components";
import { FaSistrix } from 'react-icons/fa';
import PropTypes from 'prop-types';

function TableSearchBar(props) {
  const [field, setField] = React.useState("name");

  const handleSelectChange = e => {
    setField(e?.target?.value);
  };

  return (
    <Container className={props.containerClassName} style={{ ...props.containerStyle }}>
      <FaSearchStyled />
      <CustomSelect
        className={props.selectClassName}
        style={{ ...props.style, width: "100px" }}
        onChange={handleSelectChange}
        value={field}
      >
        <option value={props?.defaultValue}>{props?.defaultLabel}</option>
        {
          Array.isArray(props?.fields) &&
          props?.fields?.map((fd, ind) => (
            <option key={ind.toString()} value={fd?.value}>{fd?.label}</option>
          ))
        }
      </CustomSelect>
      <CustomInput
        className={props.className}
        style={{ ...props.style }}
        placeholder={props.placeholder || ""}
        onChange={props.onChange.bind(this, field)}
        value={props.value}
      />
    </Container>
  );
}

TableSearchBar.propTypes = {
  value: PropTypes.string,
  fields: PropTypes.array,
  defaultValue: PropTypes.string,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string
};

TableSearchBar.defaultProps = {
  defaultValue: "name",
  defaultLabel: "Nom",
  onChange: () => console.log("Please attach an onChange function to [TableSearchBar]"),
  containerClassName: "",
  containerStyle: {},
  className: "",
  style: {},
  placeholder: "Rechercher"
};

export default TableSearchBar;

const Container = styled.div`
  background-color: white;
  border: none;
  border-radius: 100px;
  display: flex;
  align-items:center;
  padding: 5px 20px;
  outline: none;
  position: relative !important;
  width: 65%;
  height: 90%;
`;

const FaSearchStyled = styled(FaSistrix)`
  font-size: 14px;
  opacity: 1;
  color: black;
`;

const CustomInput = styled.input`
  border: none;
  outline: none;
  margin-left: 18px;
  font-size: 14px;
  width: 87%;
`;

const CustomSelect = styled.select`
  border: none;
  outline: none;
  margin-left: 18px;
  font-size: 14px;
  width: 87%;
`;